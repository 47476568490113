
import { defineComponent, ref } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useRouter } from "vue-router";
import roc_list, {
  business_natures,
  group_types,
  industry_types_pc,
  business_types,
} from "@/core/data/genericData";
import axios from "axios";
import { mask } from "vue-the-mask";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { ActionsFi } from "@/store/enums/StoreFiEnums";
import JwtService from "@/core/services/JwtService";
import moment from "moment";

export default defineComponent({
  name: "add-client-modal",
  directives: { mask },
  components: {},

  setup() {
    const store = useStore();
    // const nob_data = business_natures;
    // const group_type_data = group_types;
    // const business_types_data = business_types;
    // const roc_list_data = roc_list;
    const group_type_data = ref([]);
    const nob_data = ref([]);
    const business_types_data = ref([]);
    const roc_list_data = ref([]);
    const uom_data = ref([]);
    const material_condition_data = ref([]);

    const industry_types_data = industry_types_pc;
    const formRef = ref<null | HTMLFormElement>(null);
    const addStockRegisterModal = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const supplier_data = ref<Array<Supplier>>([]);
    const branch_data = ref<Array<Branch>>([]);
    const router = useRouter();

    const product_data = ref([
      // {
      //   product_id: 2,
      //   product_name: "AMNS PPGI Coil IS-2062 E250A",
      //   product_short_name: "AM/NS PPGI Coil IS-2062 E250A",
      // },
      // {
      //   product_id: 1,
      //   product_name: "JSW PPGI Coil IS-2062 E250 BR",
      //   product_short_name: "JSW PPGI Coil IS-2062 E250 BR",
      // },
    ]);

    interface Supplier {
      company_id: number;
      company_name: string;
      company_name_search: string;
      company_name_alternative: string;
      company_nob_id: number;
      company_nob_name: string;
      company_group_type_id: number;
      company_group_type_name: number;
      company_industry_type_id: number;
      company_industry_type_name: string;
      company_business_type_id: number;
      company_business_type_name: string;
      company_roc_id: number;
      company_roc_name: string;
      company_doi: string;
      company_cin_llpin: string;
      company_registration_number: string;
      company_pan: string;
      company_website: string;
      company_verified_yes_no: number;
      company_trade_type_id: number;
      active: number;
    }

    interface Branch {
      company_branch_id: number;
      branch_name: string;
      company_id: number;
      business_email_json: string;
      business_mobile_json: string;
      state_name: string;
      city_name: string;
      address_line_1: string;
      address_line_2: string;
      gstin: string;
      gst_type: string;
      gst_return_frequency: string;
      verified_yes_no: number;
      active: number;
    }

    getAddData();
    getCompanyList();
    getUOM();
    getMaterialCondition();
    // getProductList();
    // getBranchList();

    // console.log("industry_types_data");console.log(industry_types_data);

    // interface Iind_ty_pc  {
    //   industry_type_name,
    //   industry_type_id,
    //   parent_industry_id
    // }

    // var industry_types_data = ref<Array<Iind_ty_pc>>([]);
    // var ind_ty_pcT = ref<Array<Iind_ty_pc>>([])
    // var k =  0;

    // for (let j = 0; j < industry_types_pc.length; j++) {

    //     ind_ty_pcT.value = Array({
    //       industry_type_name : industry_types_pc[j]['company_industry_type_name'],
    //       industry_type_id : industry_types_pc[j]['company_industry_type_id'],
    //       parent_industry_id : 0
    //     });

    //   industry_types_data.value.splice(k, ind_ty_pcT.value.length, ...ind_ty_pcT.value);

    //   if(industry_types_pc[j]['subtype']){

    //     console.log(industry_types_pc[j]['subtype']);

    //     for (let i = 0; i < industry_types_pc[j]['subtype'].length; i++) {
    //       ind_ty_pcT.value = Array({
    //         industry_type_name :  industry_types_pc[j]['subtype'][i]['company_industry_type_name'],
    //         industry_type_id :  industry_types_pc[j]['subtype'][i]['company_industry_type_id'],
    //         parent_industry_id : industry_types_pc[j]['subtype'][i]['company_industry_type_id'],
    //       });
    //       k = k+1;
    //       industry_types_data.value.splice(k, ind_ty_pcT.value.length, ...ind_ty_pcT.value);
    //     }

    //   }
    //   k = k+1;
    // }

    const formData = ref({
      supplier_select: "",
      product_select: "",
      branch_select: "",
      batchno: "",
      invoiceno: "",
      invoice_date: moment().format("YYYY-MM-DD"),
      uom_select: "",
      material_condition_select: "",
      openingquantity: "",
      rate: "",
      othercharges: "",
      ctc: "",
      remark: "",

      // name: "",
      // enquiryNumber: "",
      // alternativename: "",
      // nob_select: "",
      // group_type_select: "",
      // industry_type_select: "",
      // business_type_select: "",
      // incorporationateDate: "",
      // pan: "",
      // cinn: "",
      // llpinn: "",
      // registrationNo: "",
      // roc_select: "",
      // website: "",
    });

    const rules = ref({
      supplier_select: [
        {
          required: true,
          message: "Supplier is required",
          trigger: "change",
        },
      ],
      product_select: [
        {
          required: true,
          message: "Product is required",
          trigger: "change",
        },
      ],
      // batchno: [
      //   {
      //     required: true,
      //     message: "Batch No.",
      //     trigger: "change",
      //   },
      // ],
      invoiceno: [
        {
          required: true,
          message: "Invoice No. required",
          trigger: "change",
        },
      ],
      invoice_date: [
        {
          required: true,
          message: "Invoice Date is required",
          trigger: "change",
        },
      ],
      uom_select: [
        {
          required: true,
          message: "UOM is required",
          trigger: "change",
        },
      ],
      material_condition_select: [
        {
          required: true,
          message: "Material Condition is required",
          trigger: "change",
        },
      ],
      openingquantity: [
        {
          required: true,
          message: "Opening quantity is required",
          trigger: "change",
        },
      ],
      rate: [
        {
          required: true,
          message: "Rate is required",
          trigger: "change",
        },
      ],
      othercharges: [
        {
          required: true,
          message: "Other Charges is required",
          trigger: "change",
        },
      ],
      ctc: [
        {
          required: true,
          message: "CTC is required",
          trigger: "change",
        },
      ],
      // remark: [
      //   {
      //     required: true,
      //     message: "Remark is required",
      //     trigger: "change",
      //   },
      // ],
    });

    const setCompanyData = async (data) => {
      // let product_list = [];
      debugger;

      const db_data = {
        company_id: data.supplier_select,
        product_id: data.product_select,
        company_branch_id: data.branch_select,
        product_batch_no: data.batchno,
        invoice_no: data.invoiceno,
        invoice_date: data.invoice_date,
        uom_id: data.uom_select,
        opening_qunatity: data.openingquantity,
        rate: data.rate,
        other_charges: data.othercharges,
        cost_to_company: data.ctc,
        material_condition_id: data.material_condition_select,
        stock_remark: data.remark,
        created_user_id: "1",
        created_ip: "0.0.0.0",
        created_uagent: "Test",
        active: false,
      };
      console.log(JSON.stringify(db_data));
      try {
        await axios
          .post(
            "https://elogicalservices.mymetal.in/mm_stock_entry/create",
            db_data,
            {
              headers: {
                "x-api-key": "4HTvrHxuh98GvP3XWYXaUYMHgDnOuMa1aHXtDD95",
              },
            }
          )
          .then(({ data }) => {
            loading.value = false;
            console.log(data);
            setTimeout(() => {
              loading.value = false;

              Swal.fire({
                text: "Form has been successfully submitted!",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                hideModal(addStockRegisterModal.value);
              });
            }, 2000);
          })
          .catch(({ response }) => {
            loading.value = false;
            console.log(response);
            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
          });
      } catch (e) {
        loading.value = false;
        console.log(e);
      }
    };
    // const setCompanyData = async (data) => {
    //   var user = JSON.parse(JwtService.getToken());

    //   var dt = ("0" + data.incorporationateDate.getDate()).slice(-2);
    //   var mo = ("0" + (data.incorporationateDate.getMonth() + 1)).slice(-2);
    //   var yy = data.incorporationateDate.getFullYear();
    //   var dtt = yy + "-" + mo + "-" + dt;
    //   var cinLlpin = data.llpinn === "" ? data.cinn : data.llpinn;

    //   const db_data = {
    //     company_name: data.name,
    //     company_name_alternative: data.alternativename,
    //     company_nob: data.nob_select,
    //     company_group_type: data.group_type_select,
    //     company_industry_type: data.industry_type_select,
    //     company_business_type: data.business_type_select,
    //     company_roc: data.roc_select === "" ? 0 : data.roc_select,
    //     company_doi: dtt,
    //     company_cin_llpin: cinLlpin === "" ? 0 : cinLlpin,
    //     company_registration_number:
    //       data.registrationNo === "" ? 0 : data.registrationNo,
    //     company_pan: data.pan,
    //     company_website: data.website,
    //     company_verified_yes_no: false,
    //     active: true,
    //     created_user_id: user.user_id,
    //   };

    //   console.log(db_data);

    //   await store
    //     .dispatch(Actions.CUST_ADD_COMPANY, db_data)
    //     .then(({ data }) => {
    //       console.log(data);

    //       if (data.company_id) {
    //         setTimeout(() => {
    //           loading.value = false;
    //           //
    //           Swal.fire({
    //             text: "Company has been successfully created.",
    //             icon: "success",
    //             buttonsStyling: false,
    //             confirmButtonText: "Ok, got it!",
    //             customClass: {
    //               confirmButton: "btn btn-primary",
    //             },
    //           }).then(() => {
    //             hideModal(addCompanyModalRef.value);
    //             router.go(0);
    //           });
    //         }, 2000);
    //       } else {
    //         loading.value = false;

    //         Swal.fire({
    //           text: "Sorry, looks like there are some errors detected, please try again.",
    //           icon: "error",
    //           buttonsStyling: false,
    //           confirmButtonText: "Ok, got it!",
    //           customClass: {
    //             confirmButton: "btn btn-primary",
    //           },
    //         });
    //         return false;
    //       }
    //     })
    //     .catch(({ response }) => {
    //       console.log(response);
    //     });
    // };

    const submit = async () => {
      loading.value = true;

      if (!formRef.value) {
        loading.value = false;
        return;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          await setCompanyData(formData.value);
        } else {
          loading.value = false;
          return false;
        }
      });
    };

    async function getUOM() {
      await store
        .dispatch(ActionsFi.CUST_GET_UOM)
        .then(({ data }) => {
          uom_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getMaterialCondition() {
      await store
        .dispatch(ActionsFi.CUST_GET_MATERIAL_CONDITION_DATA)
        .then(({ data }) => {
          debugger;
          material_condition_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getCompanyList() {
      //trade type 1 for Supplier
      let values = { trade_type: 1 };
      await store
        .dispatch(Actions.CUST_GET_SUPPLIER_LIST, values)
        .then(({ data }) => {
          debugger;
          supplier_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getWarehouseList() {
      let values = {
        company_id: formData.value["supplier_select"],
        // page: 1,
        // records_per_page: 10,
      };
      await store
        .dispatch(Actions.CUST_GET_WAREHOUSE_LIST, values)
        .then(({ data }) => {
          debugger;
          branch_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getProductList() {
      var values = { search_term: "", company_id: 0 };
      loading.value = true;
      await store
        .dispatch(Actions.CUST_GET_PRODUCT_DROPDOWN_LIST, values)
        .then(({ data }) => {
          debugger;
          product_data.value = data;
          loading.value = false;
          // console.log(JSON.stringify(data.data.result_list));
          // console.log(JSON.stringify(product_data.value));
        })
        .catch(({ response }) => {
          product_data.value = [];
          console.log(response);
          loading.value = false;
        });
    }

    async function getAddData() {
      await store
        .dispatch(Actions.CUST_GET_COMPANY_PAGE_DATA)
        .then(({ body }) => {
          nob_data.value = body.business_natures;
          group_type_data.value = body.group_types;
          group_type_data.value.splice(0, 1);
          business_types_data.value = body.business_types;
          roc_list_data.value = body.roc_list;
          roc_list_data.value.splice(0, 1);
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    return {
      group_type_data,
      industry_types_data,
      business_types_data,
      roc_list_data,
      formData,
      nob_data,
      product_data,
      rules,
      submit,
      formRef,
      getCompanyList,
      getWarehouseList,
      getMaterialCondition,
      getProductList,
      loading,
      addStockRegisterModal,
      uom_data,
      supplier_data,
      branch_data,
      material_condition_data,
    };
  },
});
